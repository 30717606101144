import gql from "graphql-tag";

export const FaqQuery = gql `
query FaqPAge($lang: I18NLocaleCode) {
  faqPage(locale: $lang) {
    data {
      id
      attributes {
        title
        discription
        asked_questions
        faqs {
          data {
            id
            attributes {
              title
              icon
              questions {
                id
                question
              }
              seo {
                permalink
              }
            }
          }
        }
      }
    }
  }
}
  `;


export const SingleFAQ = gql `
query getFaq($permalink: String,$lang: I18NLocaleCode) {
  faqs(locale: $lang, filters: { seo: { permalink: { eq: $permalink } } }) {
    data {
      attributes {
        title
        icon
        questions {
          question
          answer
        }
        seo {
          id
          permalink
          pageTitle
          discription
        }
        locale
        localizations {
          data {
            attributes {
              locale
              seo {
                permalink
              }
            }
          }
        }
      }
    }
  }
}

  `;
